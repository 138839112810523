.nav__menu__dropdown {
    background: #fff;
    box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
    display: block;
    left: -30px;
    list-style-type: none;
    opacity: 0;
    /* padding: 10px 40px 20px 25px; */
    position: relative;
    position: absolute;
    text-align: left;
    top: 70px;
    -webkit-transform: rotateX(102deg);
    transform: rotateX(102deg);
    transition: all .5s ease;
    visibility: hidden;
    width: auto;
    z-index: 999999999;
    width: 400px;
    display: flex !important;
    flex-direction: column;
    align-content: flex-start !important;
    align-items: flex-start;
}
.nav__menu__dropdown:hover>li>a:hover {
    width: 120px;
}
li.nav-link.dropdown:not(hover) .nav__menu__dropdown {
    transition: all ease 1s;
}
.nav__menu__dropdown>li>a {
    line-height: 35px;
}
li.nav-link.dropdown:hover .nav__menu__dropdown {
    display: block;
    position: absolute;
    opacity: 1;
    transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    visibility: visible;
}
ul.nav__menu__dropdown.local-scroll li {
    text-align: left;
    padding: 15px 10px 15px 25px;
    /* height: 55px; */
    width: 65%;
}

/* innermenu */
ul.nav__menu__dropdown__inner.local-scroll li {
    padding: 10px 15px 10px;
    width: 100%;
}
ul.nav__menu__dropdown__inner {
    display: block;
    position: absolute;
}
ul.nav__menu__dropdown__inner {
    display: block;
    position: absolute;
    padding: 3px 0px 8px 0px;
    width: 210px;
    background: #fff;
    top: -50px;
    box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
    opacity: 0;
    z-index: 999;
    left: 163px;
}
li.nav-link.dropdown-inner:hover .nav__menu__dropdown__inner {
    opacity: 1;
    transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    visibility: visible;
}
.nav__menu__dropdown__inner>li>a {
    line-height: 35px;
}
li.nav-link.dropdown-inner:not(hover) .nav__menu__dropdown__inner {
    transition: all ease .7s;
}

/* innermenutwo */
/* innermenu */
ul.nav__menu__dropdown__inner_two.nav__menu.local-scroll li {
    padding: 15px 15px 10px 20px;
    width: 90%;
}
ul.nav__menu__dropdown__inner_two {
    display: block;
    position: absolute;
}
ul.nav__menu__dropdown__inner_two {
    display: block;
    position: absolute;
    
    width: 210px;
    background: #fff;
    top: 0px;
    box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
    opacity: 0;
    z-index: 999;
    left: 155px;
}

li.nav-link.dropdown-inner-two:hover .nav__menu__dropdown__inner_two {
    opacity: 1;
    transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    visibility: visible;
}
.nav__menu__dropdown__inner_two>li>a {
    line-height: 35px;
}
li.nav-link.dropdown-inner-two:not(hover) .nav__menu__dropdown__inner_two {
    transition: all ease .7s;
}
i.fa.nav-i {
    margin-left: 5px;
    position: absolute;
    top: 35px;
    right: 0;
}
/* .dropdown-inner i.fa.nav-i-ad {
    margin-left: 5px;
    position: absolute;
    top: 20px;
    right: 15px;
}
.dropdown-inner-two i.fa.nav-i-ad {
    margin-left: 5px;
    position: absolute;
    top: 20px;
    right: -20px;
} */
i.fa.nav-i-ad {
    position: absolute;
    top: 22px;
    right: 70px;
}
li.nav-link.dropdown-inner-two i.fa.nav-i-ad {
    right: 115px;
}
.nav__menu>li {
    position: relative;
    padding: 0 16px;
}
@media only screen and (max-width: 1281px){
    .nav__menu>li {
        position: relative;
        padding: 0 14px 0px 0px;
    }
    .nav__menu>li:last-child {
        padding-right: 0;
     
    }
    /* .hero {
        height: calc(100vh - 0px);
    } */
        /* padding-left: 10px; */
    
}
/* @media (min-width: 1025px){
    .container, .container-lg, .container-md, .container-sm {
        max-width: 1160px;
    }
} */
@media only screen and (min-width: 1025px) and (max-width: 1281px){
    a.nav-link.blog-space {
        margin-left: 10px;
    }
}
@media only screen and (max-width: 1024px){
    .nav__menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .nav__menu>li {
        display: inline-block;
        text-align: center;
        padding: 0 15px;
        width: 100%;
        text-align: left;
    }
    i.fa.nav-i {
        margin-right: 25px;
        position: absolute;
        top: 15px;
        right: 0;
    }
    ul.nav__menu__dropdown__inner {
        display: block;
        position: absolute;
        padding: 3px 40px 8px 15px;
        width: 209px;
        background: #fff;
        top: 40px;
        box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
        opacity: 0;
        z-index: 999;
        left: 65px;
    }
    .nav__menu__dropdown {
        top: 70px;
        list-style-type: none;
        box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
        position: relative;
        z-index: 999999999;
        padding: 10px 40px 20px 25px;
        position: absolute;
        background: white;
        opacity: 0;
        transform: rotateX(102deg);
        visibility: hidden;
        transition: all ease .5s;
        display: block;
        width: 346px;
        text-align: left;
        left: 0px;
    }
    /* .nav__menu>li>a {
        position: initial;
    } */
}

@media only screen and (max-width: 768px){
    ul.nav__menu__dropdown.local-scroll li {
        text-align: left;
        padding: 15px 10px 15px 25px;
        /* height: 55px; */
        width: 100%;
    }
    ul.nav__menu__dropdown__inner.nav__menu.local-scroll {
        padding: 0;
    }
    ul.nav__menu__dropdown__inner.nav__menu.local-scroll li {
        padding: 12px 6px 5px;
    }
    i.fa.nav-i-ad {
        position: absolute;
        top: 22px;
        right: 2px;
    }
    ul.nav__menu__dropdown__inner_two {
        display: block;
        position: absolute;
        width: 210px;
        background: #fff;
        top: 60px;
        box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
        opacity: 0;
        z-index: 999;
        left: 80px;
    }
    li.nav-link.dropdown-inner-two i.fa.nav-i-ad {
        right: 0;
    }
    .nav__menu__dropdown__inner>li>a {
        
        height: 0;
    }
    ul.nav__menu__dropdown__inner {
        display: block;
        position: absolute;
        padding: 3px 40px 8px 15px;
        width: 170px;
        background: #fff;
        top: 45px;
        box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
        opacity: 0;
        z-index: 999;
        left: 85px;
    }
}


