
/* team */
.team-section {
	padding-top: 40px;
	padding-bottom: 0
}

/* Home blogs */
.grid-item.more-blog-link {
    padding-top: 30px;
}
img.project__img.radius {
    border-radius: 0px 20px;
}
.radius.hover-shrink--zoom {
    transition: all .2s ease-in-out;
    border-radius: 0px 20px;
}
.hero__container {
    padding-top: 80px;
}
.same-hei img {
    max-height: 700px;
    object-fit: contain;
}



/* --------------------pricing for digital------------------------- */
#pricing .toggle {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0;
  padding-bottom: 75px;
  position: relative;
}

#pricing .checkbox {
  display: none;
}

#pricing .transition-all {
  transition-duration: .15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}
#pricing .cursor-pointer {
  cursor: pointer;
}
#pricing .ball {
  background: #fff;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  transition: transform 0.15s linear;
}

#pricing .checkbox:checked + .label .ball {
  transform: translateX(24px);
}

.justify-center {
  justify-content: center;
}
.flex-col {
  flex-direction: column;
}
.flex {
  display: flex;
}
.container.md{
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
}
/* 2nd plan */
#pricing .label {
  position: relative;
  background-color: #007aff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  padding: 5px;
  margin: 0 20px;
  width: 50px;
  height: 26px;
  cursor: pointer;
}
#pricing h3.rate {
  font-size: 38px;
  line-height: 40px;
  font-weight: 600;
  color: #1c1d3e;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  padding-left: 20px;
  letter-spacing: .1px;
}
#pricing ul.list-unstyled {
  margin-top: 30px;
}
#pricing .price-header p {
  margin-bottom: 10px;
}
#canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
}
#pricing .pos-r {
  position: relative;
}
section{padding: 80px 0 60px;}
#pricing .section-title{margin-bottom:60px; position: relative;}

#pricing .title-effect {width: 70px; height: 50px; top: 0; position: absolute; left: 0; opacity: 0.2; animation: rotation 12.8s steps(1) 0s infinite;}
#pricing .text-center .title-effect{left: 50%; margin-left: -25px;}
#pricing .text-center .section-title h6{padding: 15px 0;}

#pricing .title-effect .bar {background: #2575fc;}
#pricing .title-effect .bar-top {width: 100%; height: 7px; position: absolute; top: 0; left: 0; transform-origin: left top; transform: scale(0, 1); animation: bar-top 3.2s linear 0s infinite;}
#pricing .title-effect .bar-right {width: 7px; height: 100%; position: absolute; top: 0; right: 0; transform-origin: left top; transform: scale(1, 0); animation: bar-right 3.2s linear 0s infinite;}
#pricing .title-effect .bar-bottom {width: 100%; height: 7px; position: absolute; right: 0; bottom: 0; transform-origin: right top; transform: scale(0, 1); animation: bar-bottom 3.2s linear 0s infinite;}
#pricing .title-effect .bar-left {width: 7px; height: 100%; position: absolute; left: 0; bottom: 0; transform-origin: left bottom; transform: scale(1, 0); animation: bar-left 3.2s linear 0s infinite;}

#pricing .title {position: relative; color: #1c1d3e; margin-bottom: 0;}
#pricing .section-title h2{margin-bottom: 15px;}

/* #pricing .price-title.white{
  color: #fff;
} */
.white{
  color: #fff;
}
/* #pricing h3.rate.white {
  color: #fff;
} */
#pricing h4.dollarcut:after {
  content: "";
  position: absolute;
  width: 130px;
  height: 2px;
  display: block;
  background: red;
  top: 17px;
  right: 111px;
}
/* #pricing h4.dollarcut.proff:after {
  top: 66px;
} */
#pricing h4.dollarcut {
  font-size: 30px;
  margin: 0;
}
.table-section table thead tr th {
  border-top: none;
  border: none;
}
span.dollar {
  position: absolute;
  left: -5px;
  font-size: 28px;
  line-height: 20px;
  bottom: 8px;
}

li.bold {
  font-weight: 600;
}
h6.popular {
  font-size: 16px;
  margin-bottom: 0;
  letter-spacing: 1px;
  color: #ffffff;
  padding: 7px 30px;
  font-weight: 800;
}
/* ----------------------snow -------------------- */
.snow-container {
  overflow: hidden;
  pointer-events: none;
  background-color: #2071f9;
  margin-bottom: 15px;
  margin-top: -20px;
  position: absolute;
  max-width: 250px;
  color: #fff;
  top: -20px;
  left: 130px;
  border-radius: 20px;
}


.snow {
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  transform: translate3d(0, -100%, 0);
  -webkit-animation: snow linear infinite;
          animation: snow linear infinite;
}
.snow.foreground {
  background-image: url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-large-075d267ecbc42e3564c8ed43516dd557.png");
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
}
.snow.foreground.layered {
  -webkit-animation-delay: 7.5s;
          animation-delay: 7.5s;
}
.snow.middleground {
  background-image: image-url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-medium-0b8a5e0732315b68e1f54185be7a1ad9.png");
  -webkit-animation-duration: 20s;
          animation-duration: 20s;
}
.snow.middleground.layered {
  -webkit-animation-delay: 10s;
          animation-delay: 10s;
}
.snow.background {
  background-image: image-url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-small-1ecd03b1fce08c24e064ff8c0a72c519.png");
  -webkit-animation-duration: 30s;
          animation-duration: 30s;
}
.snow.background.layered {
  -webkit-animation-delay: 15s;
          animation-delay: 15s;
}

@-webkit-keyframes snow {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(15%, 100%, 0);
  }
}

@keyframes snow {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(15%, 100%, 0);
  }
}
/*--rotation--*/

@keyframes rotation {
0% {
  transform: rotate(0deg);
}
25% {
  transform: rotate(90deg);
}
50% {
  transform: rotate(180deg);
}
75% {
  transform: rotate(270deg);
}
100% {
  transform: rotate(360deg);
}
}
@keyframes bar-top {
0% {
  transform: scale(0, 1);
}
12.5% {
  transform: scale(1, 1);
}
87.5% {
  transform: scale(1, 1);
}
100% {
  transform: scale(0, 1);
}
}
@keyframes bar-right {
0% {
  transform: scale(1, 0);
}
12.5% {
  transform: scale(1, 0);
}
25% {
  transform: scale(1, 1);
}
75% {
  transform: scale(1, 1);
}
87.5% {
  transform: scale(1, 0);
}
100% {
  transform: scale(1, 0);
}
}
@keyframes bar-bottom {
0% {
  transform: scale(0, 1);
}
25% {
  transform: scale(0, 1);
}
37.5% {
  transform: scale(1, 1);
}
62.5% {
  transform: scale(1, 1);
}
75% {
  transform: scale(0, 1);
}
100% {
  transform: scale(0, 1);
}
}
@keyframes bar-left {
0% {
  transform: scale(1, 0);
}
37.5% {
  transform: scale(1, 0);
}
50% {
  transform: scale(1, 1);
}
62.5% {
  transform: scale(1, 0);
}
100% {
  transform: scale(1, 0);
}
}


/* ------------------------
  Price Table
------------------------*/
.mb-10{
  margin-bottom: 10px;
}
.mb-15{
  margin-bottom: 15px;
}
.mb-30{
  margin-bottom: 30px;
}
.mt-30{
  margin-top: 30px;
}
.mt-60{
margin-top: 60px;
}
.pr-22{
  padding-right: 22px;
}
.pr-25{
  padding-right: 25px;
}

#pricing .price-table{padding: 20px 30px 30px; border-radius: 7px; overflow: initial; position: relative; background: #ffffff; text-align: center;-webkit-box-shadow:  0px 0px 15px 0px rgb(33 34 88 / 20%);
  -moz-box-shadow:  0px 0px 15px 0px rgb(33 34 88 / 20%);
  box-shadow:  0px 0px 15px 0px rgb(33 34 88 / 20%);-webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;}
  #pricing .price-title{text-transform: uppercase; font-weight: 700; color: #2575fc;font-size: 32px; margin-top: 10px;}
  #pricing .price-header{position: relative; z-index: 9;}
  #pricing .price-value {display: inline-block; width: 100%;}
  #pricing .price-value h2 {font-size: 60px; line-height: 60px; font-weight: 800; color: #1c1d3e; margin-bottom: 0; position: relative; display: inline-block;}
  #pricing .price-value h2 span {font-size: 26px; left: -15px; line-height: 24px; margin: 0; position: absolute; top: 10px; color: #5f5f5f; font-weight: normal;}
  #pricing .price-value span {margin: 15px 0; display: block;}
  #pricing .price-list ul li {position: relative; display: block; margin-bottom: 10px;font-size: 16px;}
  #pricing .price-list ul li:last-child{margin-bottom: 0;}
  #pricing .dark-bg .price-list ul li{color: rgba(255,255,255,0.8);}
  #pricing .price-list ul li:last-child{margin-right: 0;}
  #pricing .price-list li i {color: #2575fc; line-height: 20px; font-size: 14px; margin-right: 13px;}

  #pricing .price-inside {font-size: 80px; line-height: 80px; position: absolute; left: 85%; top: 50%; transform: translateX(-50%) translateY(-50%) rotate(-90deg); font-weight: 900; color: rgb(0 0 0 / 2%);}
  #pricing .price-table::before {
    background: #007aff14;
    content: "";
    height: 190px;
    left: 0;
    position: absolute;
    top: 0%;
    width: 100%;
}
  /* #pricing .price-table.first-table::before {background: #007aff14; content: ""; height: 310px; left: -25%; position: absolute; top: -7.5%;  width: 150%;} */
  /* #pricing .price-table.active::before{    top: -5.5%;    } */
  /* background: #007affc4; */

  #pricing .price-table.style-2{background: rgba(255,255,255,0.020); box-shadow: none;}
  #pricing .price-table.style-2::before {background: rgba(255,255,255,0.030); top: 50%; transform: translateY(-50%) rotate(-10deg);}
  #pricing .price-table.style-2 .price-title{color:#005bea;}
  #pricing .price-table.style-3.active .price-title{color:#ffffff;}
  #pricing .price-table.style-2 .price-value h2, .price-table.style-3.active .price-value h2{color: #ffffff;}
  #pricing .price-table.style-2 .price-list{margin-top: 20px;}
  #pricing .price-table.style-2.active::before{transform: rotate(0);}
  #pricing .price-table.style-2 .price-inside{color: rgba(255,255,255,0.040);}
  #pricing .price-table.style-2 .btn, .price-table.style-3.active .btn{-webkit-box-shadow: 0 10px 20px rgba(255,255,255,0.1);}

  /* #pricing .price-table.active{padding: 70px 30px;} */
#pricing .price-table.style-3.active{background: #2575fc; color: #ffffff;}
#pricing .price-table.active .price-value h2 span{color: #ffffff;}
#pricing .price-table.style-3:before, .price-table.style-4:before{display: none;}
#pricing .price-table.style-3 .price-list{margin-top: 25px;}

#pricing .price-table.style-4 .price-list{margin: 25px 0;}
#pricing .price-table.style-4 h3{text-transform: capitalize;}

#pricing .btn{padding: 12px 25px; font-weight: 500; background: none; color: #1c1d3e; overflow: hidden; border-radius: 7px; border: none; position: relative; z-index: 9; transition: all 0.5s ease-in-out 0s; box-shadow: 0px 0px 15px 0px rgba(72,73,121,0.15);}
#pricing .btn.btn-theme{background: rgb(0,91,234); background: linear-gradient(90deg, rgba(0,91,234,1) 0%, rgba(37,117,252,1) 80%);  color: #ffffff;}
#pricing .btn.focus, .btn:focus{box-shadow: none;}
#pricing .btn:hover {transform: translateY(-3px);}
#pricing .btn.btn-circle{border-radius: 30px;}

#pricing .btn span {display: inline-block; opacity: 0; -webkit-transform: translate3d(10, 10px, 0); -moz-transform: translate3d(10, 10px, 0); -ms-transform: translate3d(10, 10px, 0); -o-transform: translate3d(10, 10px, 0); transform: translate3d(10, 10px, 0);
-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1); -moz-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1); -o-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1); transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);}
#pricing .price-table:hover{-webkit-box-shadow: 0px 20px 50px 0px rgb(153 153 153 / 45%); -moz-box-shadow: 0px 20px 50px 0px rgb(153 153 153 / 45%); box-shadow: 0px 20px 50px 0px rgb(153 153 153 / 45%);}

#pricing .btn::before {content: attr(data-text); height: 100%; left: 0; position: absolute; top: 0; transition: all 0.3s cubic-bezier(0.75, 0, 0.125, 1) 0s; width: 100%; padding: 12px 0;}
#pricing .btn:hover:before {opacity: 0; -webkit-transform: translate3d(0, 100%, 0); -moz-transform: translate3d(0, 100%, 0); -ms-transform: translate3d(0, 100%, 0); -o-transform: translate3d(0, 100%, 0); transform: translate3d(0, 100%, 0);}
#pricing .btn:hover span{opacity:1; -webkit-transform:translate3d(0, 0, 0); -moz-transform:translate3d(0, 0, 0); -ms-transform:translate3d(0, 0, 0); -o-transform:translate3d(0, 0, 0); transform:translate3d(0, 0, 0)}
#pricing .btn:hover span:nth-child(1){-webkit-transition-delay:0.01s; -moz-transition-delay:0.01s; -o-transition-delay:0.01s; transition-delay:0.01s}
#pricing .btn:hover span:nth-child(2){-webkit-transition-delay:0.05s; -moz-transition-delay:0.05s; -o-transition-delay:0.05s; transition-delay:0.05s}
#pricing .btn:hover span:nth-child(3){-webkit-transition-delay:0.1s; -moz-transition-delay:0.1s; -o-transition-delay:0.1s; transition-delay:0.1s}
#pricing .btn:hover span:nth-child(4){-webkit-transition-delay:0.15s; -moz-transition-delay:0.15s; -o-transition-delay:0.15s; transition-delay:0.15s}
#pricing .btn:hover span:nth-child(5){-webkit-transition-delay:0.2s; -moz-transition-delay:0.2s; -o-transition-delay:0.2s; transition-delay:0.2s}
#pricing .btn:hover span:nth-child(6){-webkit-transition-delay:0.25s; -moz-transition-delay:0.25s; -o-transition-delay:0.25s; transition-delay:0.25s}
#pricing .btn:hover span:nth-child(7){-webkit-transition-delay:0.3s; -moz-transition-delay:0.3s; -o-transition-delay:0.3s; transition-delay:0.3s}
#pricing .btn:hover span:nth-child(8){-webkit-transition-delay:0.35s;-moz-transition-delay:0.35s; -o-transition-delay:0.35s; transition-delay:0.35s}
#pricing .btn:hover span:nth-child(9){-webkit-transition-delay:0.4s; -moz-transition-delay:0.4s; -o-transition-delay:0.4s; transition-delay:0.4s}
#pricing .btn:hover span:nth-child(10){-webkit-transition-delay:0.45s; -moz-transition-delay:0.45s; -o-transition-delay:0.45s; transition-delay:0.45s}
#pricing .btn:hover span:nth-child(11){-webkit-transition-delay:0.5s; -moz-transition-delay:0.5s; -o-transition-delay:0.5s; transition-delay:0.5s}
#pricing .btn:hover span:nth-child(12){-webkit-transition-delay:0.55s; -moz-transition-delay:0.55s; -o-transition-delay:0.55s; transition-delay:0.55s}
#pricing .btn:hover span:nth-child(13){-webkit-transition-delay:0.6s; -moz-transition-delay:0.6s; -o-transition-delay:0.6s; transition-delay:0.6s}
#pricing .btn:hover span:nth-child(14){-webkit-transition-delay:0.65s; -moz-transition-delay:0.65s; -o-transition-delay:0.65s; transition-delay:0.65s}
#pricing .btn:hover span:nth-child(15){-webkit-transition-delay:0.7s; -moz-transition-delay:0.7s; -o-transition-delay:0.7s; transition-delay:0.7s}
#pricing .btn:hover span:nth-child(16){-webkit-transition-delay:0.75s; -moz-transition-delay:0.75s; -o-transition-delay:0.75s; transition-delay:0.75s}
#pricing .btn:hover span:nth-child(17){-webkit-transition-delay:0.8s; -moz-transition-delay:0.8s; -o-transition-delay:0.8s; transition-delay:0.8s}
#pricing .btn:hover span:nth-child(18){-webkit-transition-delay:0.85s; -moz-transition-delay:0.85s; -o-transition-delay:0.85s; transition-delay:0.85s}
#pricing .btn:hover span:nth-child(19){-webkit-transition-delay:0.95s; -moz-transition-delay:0.95s; -o-transition-delay:0.95s; transition-delay:0.95s}
#pricing .btn:hover span:nth-child(20){-webkit-transition-delay:1s; -moz-transition-delay:1s; -o-transition-delay:1s; transition-delay:1s}
/* badge */
.vc {
  background: #3b98ff;
  color: #fff;
  width: auto;
  text-align: center;
  padding: 1.5px 18px;
  position: relative;
}
div#badge {
  position: absolute;
  margin-left: 450px;
  top: 1px;
}
#badge h6 {
  font-size: 14px;
}
.vc:after {
  width: 0;
  height: 0;
  border: 10.5px solid transparent;
  position: absolute;
  content: "";
  border-top-color: #3b98ff;
  right: -20px;
  top: 0px;
  border-left-color: #3b98ff;
}
.vc:before {
  width: 0;
  height: 0;
  border: 12px solid transparent;
  position: absolute;
  content: "";
  border-bottom-color: #3b98ff;
  right: -22px;
  top: -3px;
}
label.name {
  font-weight: 600;
}
/* .box-print:hover {
  box-shadow: 0 1px 20px 1px rgb(59 152 255 / 63%);
}
.box-print {
  padding: 30px 10px;
  box-shadow: 0 1px 15px rgb(110 110 110 / 62%);
  margin: 20px 10px;
  max-height: 190px;
  border-radius: 15px 0px;
} */

/* -------------------------table section---------------- */
table{
  width: 100%;
}
#pricing .price-table.table-section::before{
  display: none;
}
.table-section th {
  font-size: 16px;
}
.table-section th i.fa {
  font-size: 18px;
  color: #0b63ef;
}
.table-section th.t-head {
  font-weight: 600;
}
#pricing ul.list-unstyled .t-head.color-blue {
  color: #2273fb;
  font-size: 16px;
  margin-top: 20px;
}
span.blue{
  color: #2273fb;
  margin-left: 5px;
} 
.table-section th.h-50 {
 height: 50px;
}
.table-section th i.fa.fa-times {
  color: red;
}


.read_more  {
  display: none;
}

.show {
   display: block!important;
}
/*--------------------------- FAQ -----------------------*/
.faq-drawer {
  margin-bottom: 30px;
}

.faq-drawer__content-wrapper {
  font-size: 1.25em;
  line-height: 1.4em;
  max-height: 0px;
  overflow: hidden;
  transition: 0.25s ease-in-out;
}
input[type=checkbox]+label, input[type=radio]+label{
  line-height: 40px !important;
}
.faq-drawer__title {
  border-top: #000 1px solid;
  cursor: pointer;
  display: block;
  font-size: 1.25em;
  font-weight: 700;
  padding: 20px 30px 5px 0;
  position: relative;
  margin-bottom: 0;
  transition: all 0.25s ease-out;
}

.faq-drawer__title::after {
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: " ";
  display: inline-block;
  float: right;
  height: 10px;
  left: -20px;
  position: relative;
  right: 20px;
  top: 2px;
  transform: rotate(135deg);
  transition: 0.35s ease-in-out;
  vertical-align: top;
  width: 10px;
}

/* OPTIONAL HOVER STATE */
.faq-drawer__title:hover { 
  color: #4E4B52  ;
}
.faq-drawer__trigger:checked
  + .faq-drawer__title{
    color: #2273fb;
    padding-bottom: 20px;
  }
.faq-drawer__trigger:checked
  + .faq-drawer__title
  + .faq-drawer__content-wrapper {
  max-height: none;
}

.faq-drawer__trigger:checked + .faq-drawer__title::after {
  transform: rotate(-45deg);
  transition: 0.25s ease-in-out;
}

input[type="checkbox"] {
  display: none;
}


.read-more {
  /* You can update this variable directly in the html by adding a style property to the .read-more element */
  --line-clamp: 3;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.read-more__text {
  display: -webkit-box;
  -webkit-line-clamp: var(--line-clamp);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.read-more__checkbox {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.read-more__checkbox ~ .read-more__label {
  cursor: pointer;
  font-size: 0.75em;
  font-weight: 700;
  text-decoration: underline;
}

/* Don't forget focus and hover styles for accessibility! */
.read-more__checkbox:focus ~ .read-more__label {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.read-more__checkbox:hover ~ .read-more__label {
  text-decoration: none;
}

.read-more__checkbox ~ .read-more__label::before {
  content: attr(data-read-more);
}

.read-more__checkbox:checked ~ .read-more__label::before {
  content: attr(data-read-less);
}

.read-more__checkbox:checked ~ .read-more__text {
  --line-clamp: none;
  -webkit-line-clamp: var(--line-clamp);
}
/* price table below */
.row.pzero.margin-b {
  margin-bottom: 50px;
}
.price-table-below .faq-drawer__trigger:checked + .faq-drawer__title {
  color: #2273fb;
  padding-bottom: 0;
}
.margin-b .faq-drawer {
  margin-bottom: 0;
}
.price-table-below {
  padding: 50px 20px 30px;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
  background: #ebf5ff;
  box-shadow: 0px 0px 15px 0px rgb(72 73 121 / 15%);
  transition: all 0.5s ease-in-out 0s;
  text-align: left;
}
.box-print p {
  margin-bottom: 15px;
  color: #000;
}
.box-print h4 {
  font-size: 38px;
  line-height: 40px;
  font-weight: 600;
  color: #1c1d3e;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  padding-left: 20px;
  
  margin-top: 10px;
}
.price-header-below p {
  margin: 0;
  color: #007aff;
  font-weight: 600;
}
.box-print span.dollar {
  left: 0%;
  font-size: 28px;
}
.price-table-below li {
  font-size: 18px;
}
.price-table-below i.fa {
  margin-right: 7px;
  color: #007aff;
 
}
.price-table-below label.faq-drawer__title {
  font-size: 20px;
}
.faq.career-faq h2 {
  padding-bottom: 40px;
}
a.nav-link.btn {
  padding: 15px 45px;
}
/* ----------------------CAREER------------------------ */
.faq.career-faq {
  padding: 70px 0px;
}
.faq-drawer__content li {
  /* margin-left: 30px; */
  margin-bottom: 15px;
}
/* modal popup */
/* .mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em;
  color: #000;
  background: transparent url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E) 50%/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: .5;
}
body.modal-open {
  margin: 0;
  padding: 0;
} */

/* -----------------read more -------------------------- */


/* click team */
.containerddesk.icons-all {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding: 20px 0px;
  font-size: 28px;
  max-width: 250px;
  margin: 0 auto;
}
.containerddesk p {
  font-size: 16px;
}
button.onclickteam {
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 80px;
  font-size: 28px;
  text-align: center;
  color: #EEEEED;
  background-color: #1F1E1E;
  padding: 0 18px;
  transition: all 1.2s ease;
  justify-content: center;
  align-items: center;
  outline: 0;
  margin: 0 auto;
}
/* .anim {
  position: absolute;
  top: 170px;
  bottom: 0;
  max-width: 400px;
  width: 337px;
} */
/* team social media */

.containerd i {
  opacity: 0;
  font-size: 28px;
  color: #1F1E1E;
  will-change: transform;
  -webkit-transform: scale(.1);
          transform: scale(.1);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.containerd p {
  opacity: 0;
  font-size: 14px;
  color: #1F1E1E;
  will-change: transform;
  transform: scale(.1);
  transition: all .5s ease;
}
.btn_wrap:hover p {
  opacity: 1;
  transform: scale(1);
  transition: all 2s ease;
}
.btn_wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
  cursor: pointer;
  
  margin: 20px auto;
  background-color: transparent;
  border-radius: 80px;
  padding: 0 18px;
  will-change: transform;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  flex-direction: column;
}
.containerd.icons-all {
  width: 250px;
}
.btn_wrap:hover {
  /* transition-delay: .4s; */
  -webkit-transform: scale(1.1);
          transform: scale(1.1)
}

span.spans {
  position: absolute;
  display: flex;
  z-index: 99;
  width: 60px;
  height: 60px;
  border-radius: 80px;
  font-size: 28px;
  text-align: center;
  line-height: 70px;
  letter-spacing: 2px;
  color: #EEEEED;
  background-color: #1F1E1E;
  padding: 0 18px;
  transition: all 1.2s ease;
  justify-content: center;
  align-items: center;
}

.containerd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: auto;
  height: auto;
  border-radius: 80px;
  margin: 5px 0px;
}

.containerd i:nth-of-type(1) {
          -webkit-transition-delay: 1.1s;
                  transition-delay: 1.1s;
}

.containerd i:nth-of-type(2) {
          -webkit-transition-delay: .9s;
                  transition-delay: .9s;
}

.containerd i:nth-of-type(3) {
          -webkit-transition-delay: .7s;
                  transition-delay: .7s;
}

.containerd i:nth-of-type(4) {
          -webkit-transition-delay: .4s;
                  transition-delay: .4s;
}

.btn_wrap:hover span {
  -webkit-transition-delay: .25s;
          transition-delay: .25s;
  -webkit-transform: translateX(-400px);
          transform:translateX(-400px)
}

.btn_wrap:hover i {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}


.dr {
position: absolute;
bottom: 16px; 
right: 16px;
width:100px;
}
.btn_wrap:not(:hover) i.trsns {
  transform: translateY(20px);
}
.our-team img {
  position: relative;
  cursor: pointer;
}
/* .btn_wrap:not(:hover) span {
  transition-delay: 2.5s;
} */


/* .challenge {
  width: 541px;
  height: 22px;
  position: absolute;
  transform: rotate(-90deg);
  top: 569.5px;
  left: 755px;
  transform-origin: 0 0;
}
.challenge .number {
  float: right;
}
.challenge .daily {
  float: left;
}

body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  color: #fff;
  font-size: 18px;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAhFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAABqamrHx8e5ubnBwcHIyMjT09PLy8vPz8/MzMzW1tba2trY2Njd3d3j4+Pg4ODh4eHl5eXm5ubm5ubq6urq6urn5+fX19fr6+vu7u7o6Ojr6+vp6enn5+fp6enZ2dnb29vg4ODj4+Pb29vd3d3Y2Ni7xc8dAAAALHRSTlMAAQIDBAUGDCAhITM6Ozs8Pj5CRElKTE9RU1RVVllZWVpaXF5eZGRkZGlpb0QJKPIAAADPSURBVHjatZJZD4IwEIQtPZBDkPuQipzW8v//n8UQFkuMT8zrl53Mzu7pOCFkKCG0B4aBMSEYK6oTTChTogQvDAg1ff6S3DPpwoDYg+iaRy9G64vNZGqrIs+Lqp1sxWCImENbZmkcp1nZjiZZxxCmvrhlSRSGUZLdhEcxWv0Y74o0urpuEKVFx9nqaBAm6zwOXcdxwzivJSNbdNcRGPbKMNgaQoynHgPCjxB++ISHlS1Y+bysDEWNqqimE8NM0K5eKfkF6v1zFP2Uvx/gML0BQC8SZ10Cd14AAAAASUVORK5CYII=), auto;
}

.content {

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.content .shareButton.main .share, .content .shareButton.main .close, .content .shareButton.main .check {
  position: absolute;
  top: 1rem;
  left: 1rem;
  transition: all 150ms;
}
.content .shareButton.main .share, .content .shareButton.main.open .close, .content .shareButton.main.sent .check {
  transform: rotate(0) scale(1);
  opacity: 1;
}
.content .shareButton.main .close, .content .shareButton.main.open .share, .content .shareButton.main .check, .content .shareButton.main.sent .share {
  opacity: 0;
  transform: rotate(90deg) scale(0);
}

.shareButton, .shareButton.open {
  border: none;
  border-radius: 50%;
  background: #fff;
  padding: 1rem;
  overflow: hidden;
  outline: none;
  margin: 0.5rem;
  width: 24px;
  height: 24px;
  box-sizing: content-box;
  transition: all 200ms;
  position: relative;
  opacity: 1;
  transform: scale(1);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAA81BMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB5eXnBwcHf39/Z2dna2trX19fa2trd3d3d3d3g4ODg4ODj4+Pj4+Pl5eXk5OTm5ubl5eXn5+fo6Ojp6enn5+fp6ens7Ozq6urr6+vr6+vt7e3s7Ozt7e3t7e3u7u7w8PDw8PDw8PDy8vLy8vLn5+f09PTn5+fy8vLn5+fp6enx8fHr6+vq6urs7Ozr6+vr6+vv7+/x8fHw8PDw8PDv7+/v7+/t7e3t7e3u7u7r6+vr6+vu7u7t7e3t7e3u7u7r6+vq6urq6urr6+vs7Ozr6+tWIAHaAAAAUXRSTlMAAQIDBAUGBwgJCgsVKVdYWVpaWltbZGRsbHFxdXV4foGBhIeJi4yQkJGUlpiZm5yenp+foKCnqaqqsbK0tba3w8TFxsbIydPU1dXb3N3d4uOSbqQHAAABdElEQVR42q2TbVOCQBSFg4UFAXtPJZVFQWRxUROj0jRR0UxL/v+vCQhdZ2zGL96vz86Ze885e3GuYfbzL2RZADgOAJY9fsCwgOOhEA/kOcAyR5QXREmORxIFPuOUclCU7/uLaBv2bmURcn+c0pwyXAYj3x9NVwMll3KqDHP5n8lT2yXEbXvBJp+DVJ8BvKh8v3eIg+0mdkhnvFFEHjB7aUEaTjotbJmGYVq41QneJCGWzzAvPiw9ght1HaFavYGJt7wW+R0GUHoO2o5VR1q1qqG65bTnPQmCHRbkxcjFpq49qqWyVjOxOwplIcNsjCOf2AaqqoWiWkVGk/jbGLOHuBnjUrGgVpBhEz9K8F48TMRrWllVy5q+E6er9afZapVstW68Gj3s7jM9rIaQnh72dZkcRm0ZBIe2zF4yW6ip49RUOzH1Y70zlUayCbwsktmaRkIDHazmSaDz1SsN9LAON70wisLuFa3DiTKdrOLpIh9/gzPNL7Z6NKvvS5JoAAAAAElFTkSuQmCC), auto;
}
.shareButton:hover, .shareButton.open:hover {
  transform: scale(1.1) translateY(-3px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}
.shareButton:active, .shareButton.open:active {
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAdCAMAAABhTZc9AAABAlBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABmZma5ubm4uLi8vLy6urrT09PU1NTS0tLS0tLT09PZ2dnY2Njb29vc3Nzd3d3g4ODf39/f39/h4eHa2trh4eHY2Nja2trg4ODa2tri4uLj4+PX19fZ2dnX19fk5OTl5eXl5eXn5+fo6Ojo6Ojm5ubp6enq6urs7Ozo6Ojt7e3u7u7v7+/p6eno6Ojp6enr6+vq6urq6urc3Nzb29vc3Nzm5ubm5ubk5OTl5eXk5OTm5ubd3d3e3t7d3d3e3t7f39/e3t7f39/e3t7e3t7d3d2Z9AvaAAAAVnRSTlMAAQIDBAUGBwgJCgsMDQ4PEBk+QUFDXV9gYWNscHB2eH2AgYaJiYqKiouLi4yMjY2Sk5WYmZqam52en6KisLGxsbKztre3v8DIyMnJ09PV1tfY4OXm50OBxC8AAAFqSURBVCjPjZPZSsNAFIY7WyZJXQpFLFpEn0IoxRbfW0VFUXwDwStRW6RKUUkyq2cmUxPXOJNcHL6c/6xBrb8O+mLaXygKhvXPZ4paCCFn2Za1dsEDBYQwXKAGrvugosAIJrikRhsdMFpAQmm/l1CVPdwppY3xGPkXA+PDzGQGJ4RcCMddbE8xpqw9mAmICLF59zSTCrzLOhCmNNmbaA3hvNE7EoBB21HQ5ePnXGorJWOIsKR9VoB2SQmJdjbnQonC+caMRp2bewFKvguEsl30UuhXr0yWCF+2l1I5y1EWjZ6EKHIoBGMS84h3ToSs6P5UirwAMQjD44itH9bphy9Q8I1Xa77f4q6oqxDX59zfhpxl7iiPIOfraci5rHc0z6p607RWL7SH8fFEufY4g2wcCLXoVdnndDgryj7H3eO86nOYERsYmWsaE3yu6jNy2o6vbaVUvd0+Agvj/8duNOxVw0427HPTv/DTeQcndQotgtF9KQAAAABJRU5ErkJggg==), auto;
}
.shareButton svg, .shareButton.open svg {
  display: block;
  fill: #FF7472;
  width: 24px;
  height: 24px;
  opacity: 1;
  transition: all 150ms;
  transform: scale(1);
}

.fb, .shareButton.open.ig {
  transition-delay: 100ms;
}

.tw, .shareButton.open.tw {
  transition-delay: 50ms;
}

.ig, .shareButton.open.fb {
  transition-delay: 0ms;
}

.fb, .tw, .ig {
  width: 0;
  height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
  opacity: 0;
  transform: scale(0);
}
.fb svg, .tw svg, .ig svg {
  width: 0;
  height: 0;
  opacity: 0;
  transform: scale(0);
} */
/* .searchbar {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  border: none;
  padding: 1rem;
  padding-left: 4rem;
  width: 100%;
  box-shadow: 0px 5px 13px 0px #DEE4F1;
  border-radius: 0.8rem;
}

.searchbar:focus {
  outline: none;
  box-shadow: 0 0 0 1.5px #DEE4F1, 0px 5px 13px 0px #DEE4F1;
}

.faq {
  margin: 3rem 0;
}

.question-wrapper {
  width: 35rem;
  border-bottom: 1px solid #DEE4F1;
  margin: 0 auto;
  padding: 1rem;
  transition: 1s;
}

.question {
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  color: #212943;
  display: flex;
  justify-content: space-between;
}
.question svg {
  width: 1rem;
  height: 1.5rem;
  fill: #9EA6AE;
}
.question svg.active {
  transform: rotate(180deg);
}
.question svg:hover {
  opacity: 0.8;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

button:focus {
  outline: none;
}

.answer {
  display: none;
}

.answer.active {
  display: block;
  text-align: left;
  padding-top: 1.5rem;
  font-weight: light;
  font-size: 0.8rem;
  line-height: 1.5;
  color: #9EA6AE;
  height: 0%;
  animation: slidein 0.4s;
  animation-fill-mode: forwards;
}

@keyframes slidein {
  from {
    opacity: 0.3;
    transform: translateY(-20%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
} */
/* #socialShare {
  width: 100%;
  margin-top: 15px;
  text-align: center;
}

#socialShare a,
#socialShare > .socialBox {
  position: relative;
  float: none;
  display: inline-block;
  color: #fff;
  font-size: 20px;
  padding: 5px;
  background-color: transparent;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
}

#socialShare  a {
  background-color: rgba(0, 0, 0, 0.2);
}

#socialShare > * > span {
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.15);
  display: block;
  color: #fff;
  font-size: 16px;
  padding: 8px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all .35s ease-in-out;
  -moz-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out;
}
#socialShare > * > span:hover,
#socialShare > .open > span {
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  transform: scale(1.25);
  -webkit-transition: all .15s ease-in-out;
  -moz-transition: all .15s ease-in-out;
  transition: all .15s ease-in-out;
}

#socialGallery {
  left: 50%;
  margin: 0 auto 0;
  position: absolute;
  top: 60px;
  transform: translate(-50%, 0);
  visibility: hidden;
  width: 400px;
}

#socialGallery a {
  visibility: hidden;
  opacity: 0;
  margin: 5px 2px;
  background-color: rgba(0, 0, 0, 0.6);
  position: relative;
  top: 10px;
}

#socialGallery a > span {
  position: relative;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.socialToolBox {
  cursor: default;
}

.pointer {
  cursor: pointer
}

.facebook:hover {
  background: #3b5998 !important
}

.google:hover {
  background: #dd4b39 !important
}

.instagram:hover {
  background: #517fa4 !important
}

.tumblr:hover {
  background: #32506d !important
}

.twitter:hover {
  background: #00aced !important
}

.youtube:hover {
  background: #bb0000 !important
}

@media only screen and (max-width: 480px) {
  #socialGallery {
    width: 120px;
  }
} */
/* .share,
.share:before,
.share:after,
.ico {
  float: left;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
}

.share {
  position: relative;
}
.share:before, .share:after {
  content: "";
  will-change: transform;
}
.share:before {
  background: url(https://iconmonstr.com/wp-content/g/gd/makefg.php?i=../releases/preview/2012/png/iconmonstr-plus-6.png&r=0&g=0&b=0) center/15px no-repeat;
  z-index: 5;
  transition: 0.3s;
}
.share:after {
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: -1;
  transition: 0.39s;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.share.active:before {
  transform: rotate(45deg);
}
.share.active:after {
  width: 30px;
  height: 30px;
}

.ico {
  display: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: 0.3s;
  animation: 0.3s left forwards;
  opacity: 0;
  will-change: animation;
}
.ico:nth-child(1) {
  background: #1da1f2 url("https://iconmonstr.com/wp-content/g/gd/makefg.php?i=../releases/preview/2012/png/iconmonstr-twitter-5.png&r=255&g=255&b=255") center/30px no-repeat;
  right: -132px;
}
.ico:nth-child(2) {
  background: #46629e url("https://iconmonstr.com/wp-content/g/gd/makefg.php?i=../releases/preview/2012/png/iconmonstr-facebook-5.png&r=255&g=255&b=255") center/30px no-repeat;
  right: -66px;
}
.ico:nth-child(3) {
  background: #2867b2 url("https://iconmonstr.com/wp-content/g/gd/makefg.php?i=../releases/preview/2012/png/iconmonstr-linkedin-5.png&r=255&g=255&b=255") center/30px no-repeat;
}
.ico.active {
  display: block;
  animation: 0.48s right forwards;
}
.ico.active:nth-child(1) {
  animation-delay: 0.39s;
}
.ico.active:nth-child(2) {
  animation-delay: 0.2s;
}

@keyframes right {
  50% {
    transform: translateX(170%);
  }
  100% {
    opacity: 1;
    transform: translateX(130%);
  }
}
@keyframes left {
  0% {
    opacity: 1;
    transform: translateX(130%);
  }
  100% {
    opacity: 0;
    transform: translateX(0);
    top: 0;
  }
} */
/* * {
  margin: 0;
  padding: 0;
}



.containerd i {
  opacity: 0;
  font-size: 28px;
  color: #1F1E1E;
  will-change: transform;
  -webkit-transform: scale(.1);
          transform: scale(.1);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.btn_wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
  cursor: pointer;
  width: 240px;
  margin: 25px auto;
  height: 72px;
  background-color: #EEEEED;
  border-radius: 80px;
  padding: 0 18px;
  will-change: transform;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
} */

.btn_wrap:hover {
  /* transition-delay: .4s; */
  -webkit-transform: scale(1.1);
          transform: scale(1.1)
}

/* span.spans {
  position: absolute;
  z-index: 99;
  width: 240px;
  height: 72px;
  border-radius: 80px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
  text-align: center;
  line-height: 70px;
  letter-spacing: 2px;
  color: #EEEEED;
  background-color: #1F1E1E;
  padding: 0 18px;
  -webkit-transition: all 1.2s ease;
  transition: all 1.2s ease;
}

.containerd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 240px;
  height: 64px;
  border-radius: 80px;
}

.containerd i:nth-of-type(1) {
          -webkit-transition-delay: 1.1s;
                  transition-delay: 1.1s;
}

.containerd i:nth-of-type(2) {
          -webkit-transition-delay: .9s;
                  transition-delay: .9s;
}

.containerd i:nth-of-type(3) {
          -webkit-transition-delay: .7s;
                  transition-delay: .7s;
}

.containerd i:nth-of-type(4) {
          -webkit-transition-delay: .4s;
                  transition-delay: .4s;
}

.btn_wrap:hover span {
  -webkit-transition-delay: .25s;
          transition-delay: .25s;
  -webkit-transform: translateX(-280px);
          transform: translateX(-280px)
}

.btn_wrap:hover i {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}


.dr {
position: absolute;
bottom: 16px; 
right: 16px;
width:100px;
} */

/* 2 */
/* .thing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  float: left;
  height: 80px;
  line-height: 80px;
  border-radius: 3px;
  margin-top: 40px;
  clear: both;
}
.thing:after {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  content: "";
}
.thing:active:after {
  height: 0;
  z-index: -1;
  opacity: 0;
}
.thing__text {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: #34b99d;
  border-radius: 3px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.thing__content {
  position: relative;
  height: inherit;
}
.thing__content:after, .thing__content:before {
  content: "";
  display: none;
  height: 100%;
  position: absolute;
  width: 6px;
  z-index: -1;
}
.thing__content:after {
  left: -6px;
}
.thing__content:before {
  right: -6px;
}
.thing__content:hover {
  z-index: 1000;
}
.thing__content:hover:after, .thing__content:hover:before {
  display: block;
}
.thing__content:hover .thing__text {
  transition: 0s all ease-out;
  transition-delay: 0s;
  opacity: 0;
  z-index: -1;
}
.thing__content:hover .thing__social {
  width: 80px;
  border-radius: 3px;
  margin-left: 6px;
}
.thing__content:hover .thing__social--first {
  margin-left: 0;
}
.thing__content:hover .thing__social:after {
  opacity: 1;
}
.thing__content:hover .thing__social--facebook {
  background-color: #496094;
}
.thing__content:hover .thing__social--twitter {
  background-color: #4ac4f3;
}
.thing__content:hover .thing__social--linkedin {
  background-color: #2388d6;
}
.thing__content:hover .thing__social--google {
  background-color: #da4835;
}
.thing__social {
  position: relative;
  display: block;
  height: 80px;
  width: 51px;
  float: left;
  color: white;
  background-color: #34b99d;
  transition: all 0.15s ease-out;
}
.thing__social--first {
  border-radius: 3px 0 0 3px;
}
.thing__social--last {
  border-radius: 0 3px 3px 0;
}
.thing__social:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: FontAwesome;
  text-align: center;
  font-size: 20px;
  line-height: 80px;
  content: "";
  opacity: 0;
  transition: 0.15s all ease-out;
}
.thing__social--facebook:after {
  content: "";
}
.thing__social--twitter:after {
  content: "";
}
.thing__social--linkedin:after {
  content: "";
}
.thing__social--google:after {
  content: "";
}
.thing__text {
  color: white;
  font-weight: bold;
  font-size: 24px;
  text-indent: -5px;
}
.thing__social:after {
  font-size: 36px;
}

.thing2 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  float: left;
  height: 48px;
  line-height: 48px;
  border-radius: 3px;
  margin-top: 10px;
  clear: both;
  float: right;
}
.thing2:after {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  content: "";
}
.thing2:active:after {
  height: 0;
  z-index: -1;
  opacity: 0;
}
.thing2__text {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: #4a524c;
  border-radius: 3px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.thing2__content {
  position: relative;
  height: inherit;
}
.thing2__content:after, .thing2__content:before {
  content: "";
  display: none;
  height: 100%;
  position: absolute;
  width: 6px;
  z-index: -1;
}
.thing2__content:after {
  left: -6px;
}
.thing2__content:before {
  right: -6px;
}
.thing2__content:hover {
  z-index: 1000;
}
.thing2__content:hover:after, .thing2__content:hover:before {
  display: block;
}
.thing2__content:hover .thing2__text {
  transition: 0s all ease-out;
  transition-delay: 0s;
  opacity: 0;
  z-index: -1;
}
.thing2__content:hover .thing2__social {
  width: 48px;
  border-radius: 3px;
  margin-left: 6px;
}
.thing2__content:hover .thing2__social--first {
  margin-left: 0;
}
.thing2__content:hover .thing2__social:after {
  opacity: 1;
}
.thing2__content:hover .thing2__social--facebook {
  background-color: #496094;
}
.thing2__content:hover .thing2__social--twitter {
  background-color: #4ac4f3;
}
.thing2__content:hover .thing2__social--linkedin {
  background-color: #2388d6;
}
.thing2__content:hover .thing2__social--google {
  background-color: #da4835;
}
.thing2__social {
  position: relative;
  display: block;
  height: 48px;
  width: 26.6666666667px;
  float: left;
  color: white;
  background-color: #4a524c;
  transition: all 0.15s ease-out;
}
.thing2__social--first {
  border-radius: 3px 0 0 3px;
}
.thing2__social--last {
  border-radius: 0 3px 3px 0;
}
.thing2__social:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: FontAwesome;
  text-align: center;
  font-size: 20px;
  line-height: 48px;
  content: "";
  opacity: 0;
  transition: 0.15s all ease-out;
}
.thing2__social--facebook:after {
  content: "";
}
.thing2__social--twitter:after {
  content: "";
}
.thing2__social--linkedin:after {
  content: "";
}
.thing2__social--google:after {
  content: "";
}
.thing2__text {
  color: white;
  font-weight: bold;
  font-size: 18px;
} */
.about-hint h6.mb-10 {
  margin-bottom: 10px;
}
.trainer-heading {
  font-size: 35px;
  margin: 0;
}
@media (min-width: 1920px) and (max-width: 2560px)  {
  #pricing h4.dollarcut:after {
    content: "";
    position: absolute;
    width: 130px;
    height: 2px;
    display: block;
    background: red;
    top: 17px;
    right: 170px !important;
}
}

@media only screen and (max-width: 2560px){


}
@media only screen and (max-width: 1440px){

  #pricing h4.dollarcut:after {
    content: "";
    position: absolute;
    width: 130px;
    height: 2px;
    display: block;
    background: red;
    top: 17px;
    right: 111px ;
  }
  .snow-container {
    overflow: hidden;
    pointer-events: none;
    background-color: #2071f9;
    margin-bottom: 15px;
    margin-top: -20px;
    position: absolute;
    max-width: 250px;
    color: #fff;
    top: -20px;
    left: 77px;
    border-radius: 20px;
}
}
@media (max-width: 1440px){

  .container, .container-lg, .container-md, .container-sm {
      max-width: 1160px;
  }
}
@media (max-width: 1331px){

  .container, .container-lg, .container-md, .container-sm {
      max-width: 1200px;
  }
}
@media only screen and (max-width: 1025px){
  .nav__menu>li {
    display: inline-block;
    text-align: center;
    padding: 0 15px;

}
#pricing .price-title {
  text-transform: uppercase;
  font-weight: 600;
  color: #2575fc;
  font-size: 28px;
  margin-top: 10px;
}
#pricing h4.dollarcut:after {
  content: "";
  position: absolute;
  width: 130px;
  height: 2px;
  display: block;
  background: red;
  top: 17px;
  right: 51px;
}
.snow-container {
  overflow: hidden;
  pointer-events: none;
  background-color: #2071f9;
  margin-bottom: 15px;
  margin-top: -20px;
  position: absolute;
  max-width: 250px;
  color: #fff;
  top: -20px;
  left: 345px;
  border-radius: 20px;
}
}
@media (max-width: 1024px){

  #pricing h4.dollarcut:after {
      content: "";
      position: absolute;
      width: 130px;
      height: 2px;
      display: block;
      background: red;
      top: 17px;
      right: 380px;
  }
}
@media (max-width: 768px){
  #pricing h4.dollarcut:after {
    content: "";
    position: absolute;
    width: 130px;
    height: 2px;
    display: block;
    background: red;
    top: 17px;
    right: 270px;
  }
  #pricing .price-table {
    padding: 20px 30px 30px;
    border-radius: 7px;
    overflow: initial;
    position: relative;
    background: #ffffff;
    text-align: center;
    box-shadow: 0px 0px 15px 0px rgb(33 34 88 / 20%);
    transition: all 0.5s ease-in-out 0s;
    margin: 20px 0px;
}
.snow-container {
  overflow: hidden;
  pointer-events: none;
  background-color: #2071f9;
  margin-bottom: 15px;
  margin-top: -20px;
  position: absolute;
  max-width: 250px;
  color: #fff;
  top: -20px;
  left: 222px;
  border-radius: 20px;
}
}

@media (min-width: 651px){
.mobonly{
  display: none;
}
}
@media (max-width: 650px) {
  section#pricing {
    padding-bottom: 0px;
}
section.Print-media {
  padding-top: 25px;
}
  .onlyweb {
    display: none;
}
#pricing .price-table {
  padding: 30px 30px 0px;
}
  .pzero .col-lg-4 {
    margin-bottom: 25px;
}
label.faq-drawer__title.for-mobile-trigger {
  font-size: 18px;
  border: 0;
}
.faq-drawer__title.for-mobile-trigger::after{
    left: -55px;
    top: 4px;
}
/* #pricing .price-table::before{
  height: 435px;
} */
.faq-drawer__trigger:checked
  + .faq-drawer__title.white{
    color: #fff;
    padding-bottom: 20px;
  }
  label.faq-drawer__title.for-mobile-trigger.white {
    color: #fff;
}
/* #pricing .price-table.first-table::before {
  top: -26.5%;
}
#pricing .price-table.active::before {
  top: -27.5%;
}
#pricing .price-table::before {
  top: -26%;
} */
.faq-drawer.for-mob ul.list-unstyled li {
  font-size: 18px;
}
/* #pricing .price-table.first-table::before {
  background: #007aff14;
  content: "";
  height: 400px;
  left: -25%;
  position: absolute;
  top: -7.5%;
  width: 150%;
} */
.mobonly ul li i{
  color: #2575fc;
    line-height: 20px;
    font-size: 14px;
    margin-right: 13px;
}
}
@media (max-width: 426px){
  #pricing h4.dollarcut:after {
    content: "";
    position: absolute;
    width: 130px;
    height: 2px;
    display: block;
    background: red;
    top: 17px;
    right: 98px;
}
#pricing .price-table::before {
  background: #007aff14;
  content: "";
  height: 190px;
  left: 0;
  position: absolute;
  top: 0%;
  width: 100%;
}
#badge h6 {
  font-size: 13.5px;
}
.vc {
  background: #3b98ff;
  color: #fff;
  width: 175px;
  text-align: center;
  padding: 1.5px 18px;
  position: relative;
  margin-right: -20px;
}
div#badge {
  position: absolute;
  margin-left: 454px;
  top: 35px;
  right: 115px;
}
.snow-container {
  overflow: hidden;
  pointer-events: none;
  background-color: #2071f9;
  margin-bottom: 15px;
  margin-top: 0px;
  position: absolute;
  max-width: 250px;
  color: #fff;
  top: -20px;
  left: 60px;
  border-radius: 20px;
}
.team-col-3 {
  flex: 0 0 100%;
  max-width: 100%;
}
}
@media (max-width: 391px){
  .faq-drawer__title {
    font-size: 16px;
  }
  #pricing h4.dollarcut:after {
    content: "";
    position: absolute;
    width: 130px;
    height: 2px;
    display: block;
    background: red;
    top: 17px;
    right: 85px;
  }
  .container.faq {
    padding: 40px;
  }
  .snow-container {
    overflow: hidden;
    pointer-events: none;
    background-color: #2071f9;
    margin-bottom: 15px;
    margin-top: -20px;
    position: absolute;
    max-width: 250px;
    color: #fff;
    top: -10px;
    left: 40px;
    border-radius: 20px;
}
}
@media (max-width: 375px){

  #pricing h4.dollarcut:after {
    content: "";
    position: absolute;
    width: 130px;
    height: 2px;
    display: block;
    background: red;
    top:17px;
    right: 70px;
  }
  .snow-container {
    overflow: hidden;
    pointer-events: none;
    background-color: #2071f9;
    margin-bottom: 15px;
    margin-top: -20px;
    position: absolute;
    max-width: 250px;
    color: #fff;
    top: -10px;
    left: 30px;
    border-radius: 20px;
}
}
@media (max-width: 320px){
  div#badge {
    position: absolute;
    right: 85px;
    top: 34px;
}
  #pricing h4.dollarcut:after {
    content: "";
    position: absolute;
    width: 130px;
    height: 2px;
    display: block;
    background: red;
    top: 17px;
    right: 50px;
  }
  .snow-container {
    overflow: hidden;
    pointer-events: none;
    background-color: #2071f9;
    margin-bottom: 15px;
    margin-top: -20px;
    position: absolute;
    max-width: 250px;
    color: #fff;
    top: -10px;
    left: 2px;
    border-radius: 20px;
}
}


