@media only screen and (max-width:540px) {
    .custom--home-head {
        margin-block: 1rem;
        font-size: 32px;
    }
}

@media only screen and (min-width:768px) {
    .custom--home-head {
        text-align: right;
    }

    .client-carousel-custom {
        padding-inline-end: 0;
    }
}


/* Blog SEO */
.seo-blog .profile-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.seo-blog .profile-img {
    width: 80px;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
    margin-inline-end: 0.8rem;
}

.seo-blog .profile-img:hover {
    border-radius: 50%;
}

.seo-blog .profile-container a {
    font-weight: bold;
    color: black;
    text-decoration: underline;
    text-underline-offset: 5px;
}

.seo-blog .profile-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.seo-blog .profile-designation {
    font-size: medium;
    margin-top: 0.5rem;
}

/* Blog SEO */

p.project-description__text {
    text-align: justify;
}