#pricing .price-table::before {
  background: none;
  content: unset;
}

#pricing .price-header {
  text-align: start;
}

#pricing .price-title {
  font-size: large;
  letter-spacing: 1px;
}

#pricing h3.rate {
  font-size: xxx-large;
  margin: 0.3em 0em 0.5em 0em;
}

#pricing button[data-text="Get Started"] {
  width: 100%;
}

#pricing .faq-drawer__title::after {
  top: 12px;
}

#pricing .row.pzero {
  justify-content: center;
}

#pricing .price-table.active {
  background: linear-gradient(90deg,
      rgba(0, 91, 234, 1) 0%,
      rgba(37, 117, 252, 1) 80%) !important;
  color: white !important;
  transform: scale(103%);
}

#pricing .price-table.active .snow-container {
  top: unset;
  right: -20px;
  left: unset;
  background-color: white;
  border-radius: 8px;
}

#pricing .price-table.active .price-title,
#pricing .price-table.active .rate,
#pricing .price-table.active .t-head,
#pricing .price-table.active .faq-drawer label,
#pricing .price-table.active .price-list li i {
  color: white !important;
}

#pricing .price-table.active .popular {
  background-color: #007aff14;
  font-size: xx-small;
  color: rgba(0, 91, 234, 1) !important;
}

#pricing .price-table.active button[data-text="Get Started"] {
  background: white;
  color: black !important;
}

#pricing .faq-drawer {
  margin-bottom: unset;
}

@media screen and (max-width: 650px) {
  #pricing .faq-drawer__title::after {
    left: 1em;
  }
}

@media screen and (max-width: 426px) {
  #pricing .snow-container {
    top: -10px !important;
    /* font-size: smaller !important; */
  }

  #pricing .snow-container .popular {
    font-size: xx-small !important;
  }
}

/* Card lg-width */

@media (min-width:1024px) and (max-width:1800px) {
  #pricing .row.pzero .col-lg-3 {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media (min-width:1024px) and (max-width:1143px) {
  #pricing .faq-drawer__title::after {
    right: -24px;
    left: unset;
  }
}

@media (min-width:1144px) and (max-width:1440px) {
  #pricing .faq-drawer__title::after {
    top: -24px;
    left: initial;
    right: -24px;
  }
}

/* D-Tab E-Card Tab-md,lg */

@media (min-width:768px) and (max-width:1024px) {
  #pricing .price-table {
    margin: 20px 0px;
  }
}

@media (max-width:768px) {
  #pricing.price-table-below {
    margin: 20px 0px;
  }
}


/* Dollarcut Laks Width */

#pricing h4.dollarcut.lkw::after {
  width: 150px;
}

/* SMM */

@media (min-width: 1920px) and (max-width: 2560px) {
  #pricing h4.dollarcut::after {
    width: 150px;
    top: 52px;
  }
}

#pricing h4.dollarcut::after {
  top: 52px;
  left: 0px;
  right: unset !important;
}

.pricing-mx-auto {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Home Logo Carousel */

.hlc__head {
  text-align: center;
}

.hlc__container {
  height: 100px;
}

.hlc__image {
  display: flex;
  justify-content: center;
}

.hlc__image img {
  max-height: 80px;
  /* filter: grayscale(100%); */
}

.hlc__image img:hover {
  /* transform: scale(1.3); */
  /* filter: grayscale(0%); */
}

/* Horizontal Scroll*/

.portfolio__desc--wrap,
.portfolio__item:last-child,
.portfolio__item:nth-last-child(2) {
  display: none;
}

@media (min-width:1024px) {

  .portfolio__desc--wrap,
  .portfolio__item:last-child,
  .portfolio__item:nth-last-child(2) {
    display: block;
  }

  .portfolio {
    display: flex;
    width: 100%;
    overflow: hidden;
    scroll-snap-type: x mandatory;
    height: 100vh;
    padding-bottom: 0px !important;
  }

  .portfolio__item {
    margin: 1em;
    padding: 2em;
    flex: 0 0 50%;
    scroll-snap-align: start;
  }

  .portfolio__desc--wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .portfolio__desc--btn a {
    background: rgb(0, 91, 234);
    color: #ffffff !important;
    border-radius: 25px;
    padding: .3em 1em;
    font-size: medium;
  }

  .portfolio__desc--btn a span {
    transform: rotate(-45deg);
  }
}

@media (min-width:1024px) {
  .more-works {
    display: none;
  }
}